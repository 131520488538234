import firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/firestore";
import config from '../config/config'

let instance;

export default function getFirebase() {
    if (typeof window !== 'undefined') {
    //   if (instance) return instance;
      instance = firebase.initializeApp(config);
      return instance;
    }
  
    return null;
  }