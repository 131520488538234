
const fbConfig = {
    "apiKey": "AIzaSyA5SS-08lVPJSuJPn5e54qhaWmJOa-r2HU",
    "authDomain": "429063685281-ih6d4q8tmfqsteurmedh78oig1rr823b.apps.googleusercontent.com",
    "databaseURL": "https://healthpeerng-f25a0.firebaseio.com",
    "storageBucket": "healthpeerng-f25a0.appspot.com",
    "projectId": "healthpeerng-f25a0",
    "messagingSenderId": "429063685281"
}

export default fbConfig